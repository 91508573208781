<template>
  <div>
    {{ agentFullname }}
  </div>
</template>
    
    <script>
    import {mapGetters} from 'vuex'
    export default {
        props: {object: {type: Object, required: true}},
        data(){
            return  {
            }
        },
        computed: {
            ...mapGetters({
                users: 'auth/users'
            }),
            agentFullname(){
                let agent = [...this.users].find(item => item.uid === this.object.agent)
                if(agent) return agent.fullname
                return '-'
            }
        }
    }
    </script>
    
    <style>
    
    </style>